<template>
  <div>
    <div v-if="!fondsdata.zusatzdaten || !hasListing() || !getListing()" class="pt-4">
      <p>Keine Daten vorhanden</p> 
    </div>  
    <div v-else class="text-left pb-3 pt-4">
      <h4 class="text-uppercase h4-color">Handelsinformationen</h4>
      <table class="table table-striped table-bordered">
        <colgroup>
          <col style="width: 40%"/>
          <col style="width: 60%"/>
        </colgroup>
        <tbody>
          <tr v-if="getHandelsaussetzung()">
            <td class="font-weight-bold">Handelsaussetzung</td>
            <td class="font-weight-bold">Der Fonds ist vom Handel ausgesetzt.</td>
          </tr>
          <tr v-if="hasAuszahlungsmodalitaeten()">
            <td class="font-weight-bold">Auszahlungsmodalitäten</td>
            <td>{{ getAuszahlungsmodalitaeten() }}</td>
          </tr>
          <tr v-if="hasVorkaufsrecht()">
            <td class="font-weight-bold">Besteht ein Vorkaufsrecht?</td>
            <td>{{ getVorkaufsrecht() }}</td>
          </tr>
          <tr v-if="hasUebertragungsintervall()">
            <td class="font-weight-bold">Übertragungsintervall</td>
            <td>{{ getUebertragungsintervall() }}</td>
          </tr>
          <tr v-if="fondsdata.thUmschreibeGebuehrenK">
            <td class="font-weight-bold">Umschreibungs- bzw. Abwicklungsgebühren für den Käufer in Höhe von: </td>
            <td>{{ fondsdata.thUmschreibeGebuehrenK }}</td>  
          </tr>
          <tr v-if="fondsdata.thUmschreibeGebuehrenVK">
            <td class="font-weight-bold">Umschreibungs- bzw. Abwicklungsgebühren für den Verkäufer in Höhe von: </td>
            <td>{{ fondsdata.thUmschreibeGebuehrenVK }}</td>
          </tr>
          <tr>
            <td class="font-weight-bold">Aktuelle Informationen</td>
            <td>
              <div v-if="hasAnmerkungen() === true">{{ getAnmerkungen() }}</div>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="fs-7 mt-3" style="color: red">
        * Bitte beachten Sie: Die unter Handelsinformationen veröffentlichten Daten werden uns vom Treuhänder zur
        Verfügung gestellt. Eine Gewähr für Aktualität und Richtigkeit wird nicht übernommen.
      </div>
    </div>
  </div>
</template>

<script>
import percentMixin from "@/mixins/formatPercent";

export default {
  name: "Handelsinformationen",
  props: {
    fondsdata: {},
  },
  data: () => ({
    error: null,
    notfound: false,
    unauthorized: false,
    servererror: false,
  }),
  methods: {
    hasZusatzdatenValue( keyVal ) {
      return this.getZusatzdatenValue( keyVal) != null;
    },
    getZusatzdatenValue( keyVal ) {
      var result = null;
      for( var opt of this.fondsdata.zusatzdaten ) {
        if ( opt.key === keyVal ) { result = opt.value; }
      }
      return result;
    },
    hasVorkaufsrecht() {
      return this.hasZusatzdatenValue( 'Zwm.TH.Vorkaufsrecht' );  
    },
    getVorkaufsrecht() {
      return this.getZusatzdatenValue( 'Zwm.TH.Vorkaufsrecht' ) === 'true' ? 'Ja' : 'Nein';
    },
    hasUebertragungsintervall() {
      return this.hasZusatzdatenValue( 'Zwm.TH.Übertragungsintervall' );
    },
    getUebertragungsintervall() {
      return this.getZusatzdatenValue( 'Zwm.TH.Übertragungsintervall' )  
    },
    hasListing() {
      return this.hasZusatzdatenValue( 'Zwm.Fonds.Listingstatus' );
    },
    getListing() {
      return this.getZusatzdatenValue( 'Zwm.Fonds.Listingstatus' ) === 'true';
    },
    hasHandelsaussetzung() {
      return this.hasZusatzdatenValue( 'Zwm.Fonds.Handelsaussetzung' );
    },
    getHandelsaussetzung() {
      return this.getZusatzdatenValue( 'Zwm.Fonds.Handelsaussetzung' ) === 'true';
    },
    hasAnmerkungen() {
      return this.hasZusatzdatenValue( 'Zwm.TH.Anmerkungen' );
    },
    getAnmerkungen() {
      return this.getZusatzdatenValue( 'Zwm.TH.Anmerkungen' );
    },
    hasAuszahlungsmodalitaeten() {
      return this.hasZusatzdatenValue( 'Zwm.TH.Auszahlungsmodalitäten' );
    },
    getAuszahlungsmodalitaeten() {
      return this.getZusatzdatenValue( 'Zwm.TH.Auszahlungsmodalitäten' );
    },
    
  },
  mixins: [percentMixin],
}
</script>

<style scoped>

</style>