<template>
  <div>
    <div class="text-left pb-3">
      <b-alert show variant="info">Bitte melden Sie sich an, um Zugriff auf die aktuellsten Dokumente zu diesem Fonds zu bekommen.</b-alert>
      <h4 class="text-uppercase h4-color pt-4">Verkaufsprospekt</h4>
      <table class="table table-bordered table-striped mb-5">
        <colgroup>
          <col style="width: 40%"/>
          <col style="width: 60%"/>
        </colgroup>
        <tbody>
        <tr>
          <td><span class="blurred">01.01.2018</span></td>
          <td><span class="blurred">Verkaufsprospekt</span> <i class="fas fa-file-download"></i></td>
        </tr>
        </tbody>
      </table>
      <h4 class="text-uppercase h4-color">Aktuelle Geschäftsberichte</h4>
      <table class="table table-bordered table-striped mb-5">
        <colgroup>
          <col style="width: 40%"/>
          <col style="width: 60%"/>
        </colgroup>
        <tbody>
        <tr>
          <td><span class="blurred">01.01.2021</span></td>
          <td><span class="blurred">Geschäftsbericht 2021</span> <i class="fas fa-file-download"></i></td>
        </tr>
        <tr>
          <td><span class="blurred">01.01.2020</span></td>
          <td><span class="blurred">Geschäftsbericht 2020</span> <i class="fas fa-file-download"></i></td>
        </tr>
        </tbody>
      </table>
      <h4 class="text-uppercase h4-color">Aktuelle Bestandsunterlagen</h4>
      <table class="table table-bordered table-striped mb-5">
        <colgroup>
          <col style="width: 40%"/>
          <col style="width: 60%"/>
        </colgroup>
        <tbody>
        <tr>
          <td><span class="blurred">{{this.currentDate()}}</span></td>
          <td><span class="blurred">Neueste Information</span> <i class="fas fa-file-download"></i></td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "Dokumente",
  methods: {
    currentDate() {
      return new Date().toLocaleDateString().replace('/','.').replace('/','.')
    }
  }
}
</script>

<style scoped>

</style>