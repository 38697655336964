<template>
  <div class="h-100 justify-content-center align-items-center d-flex flex-column p-2" style="background-color: white">
    <div v-if="lastKurs === null">
      <div class="fs-8">Keine Umsätze in den letzten 12 Monaten</div>
    </div>
    <div v-else>
      <div class="fs-8">Letzter Kurs</div>
      <span class="lead font-weight-bold h4-color">{{ formatPercent(lastKurs) }}
        <span v-if="lastKurs">%</span>
        <span class="fs-8 pl-3" v-if="lastKursDate">am {{ lastKursDate }}</span>
      </span>
    </div>
  </div>
</template>

<script>
import percentMixin from "@/mixins/formatPercent";

export default {
  name: "TradingData",
  props: {
    lastKurs: {
      type: Number,
      default: null,
    },
    lastKursDate: {
      type: String,
      default: null,
    },
  },
  mixins: [percentMixin],
}
</script>

<style scoped>

.fs-8 {
  font-size: 80%;
}

</style>