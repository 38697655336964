<template>
  <div>
    <div v-if="unauthorized || complianceError401" class="pt-4">
      <div class="alert alert-warning" role="alert">
        <p>Sie haben hierfür keine Berechtigung.</p>
      </div>
    </div>
    <div v-else-if="notfound" class="pt-4">
      <div class="alert alert-warning" role="alert">
        <p>Dieser Fonds konnte nicht ermittelt werden.</p>
      </div>
    </div>
    <div v-else-if="complianceError404" class="pt-4">
      <div class="alert alert-warning" role="alert">
        <p>Die Tranche ist unbekannt.</p>
      </div>
    </div>
    <div v-else-if="servererror" class="pt-4">
      <div class="alert alert-danger" role="alert">
        <p>Es ist ein Fehler aufgetreten.</p>
        <p v-if="error" class="fs-7">{{ error }}</p>
      </div>
    </div>
    <div v-else class="text-left pt-4">
      <div class="alert alert-danger" role="alert" v-if="hasFileError">
        {{ fileError }}
      </div>
      <div v-if="fondsdata.verkaufsprospekt">
        <h4 class="text-uppercase h4-color">Verkaufsprospekt</h4>
        <table class="table table-bordered table-striped mb-5">
          <colgroup>
            <col style="width: 40%"/>
            <col style="width: 60%"/>
          </colgroup>
          <tbody>
            <tr>
              <td>{{ fondsdata.verkaufsprospekt.datei.datum }}</td>
              <td><b-link @click="download( fondsdata.verkaufsprospekt )">{{ fondsdata.verkaufsprospekt.datei.anzeigename }} <i class="fas fa-file-download"></i></b-link></td>
            </tr>
          </tbody>  
        </table>
      </div>
      <div v-if="fondsdata.nachtrag">
        <h4 class="text-uppercase h4-color">Nachträge</h4>
        <table class="table table-bordered table-striped mb-5">
          <colgroup>
            <col style="width: 40%"/>
            <col style="width: 60%"/>
          </colgroup>
          <tbody>
            <tr>
              <td>{{ fondsdata.nachtrag.datei.datum }}</td>
              <td><b-link @click="download( fondsdata.nachtrag )">{{ fondsdata.nachtrag.datei.anzeigename }} <i class="fas fa-file-download"></i></b-link></td>
            </tr>
          </tbody>
        </table>
      </div>
      <h4 class="text-uppercase h4-color">Aktuelle Geschäftsberichte</h4>
      <b-table id="geschaeftsberichtetable" ref="geschaeftsberichtetable" class="mb-5"
               bordered
               striped
               responsive="xl"
               :items="fondsdata.geschaeftsberichte"
               :fields="dokumenteFields"
               thead-class="hidden-header"
      >
        <template #cell(anzeigename)="data">
          <div><b-link @click="download( data.item )">{{ data.item.datei.anzeigename }} <i class="fas fa-file-download"></i></b-link></div>
        </template>
        <template #cell(datum)="data">
          <div>{{ data.item.datei.datum }}</div>
        </template>
      </b-table>
      
      <h4 class="text-uppercase h4-color">Aktuelle Bestandsunterlagen</h4>
      <b-table id="bestandsunterlagentable" ref="bestandsunterlagentable" class="mb-5"
               bordered
               striped
               responsive="xl"
               :items="fondsdata.bestandsunterlagen"
               :fields="dokumenteFields"
               thead-class="hidden-header"
      >
        <template #cell(anzeigename)="data">
          <div><b-link @click="download( data.item )">{{ data.item.datei.anzeigename }} <i class="fas fa-file-download"></i></b-link></div>
        </template>
        <template #cell(datum)="data">
          <div>{{ data.item.datei.datum }}</div>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import router from "../../router";
import axios from "axios";

export default {
  name: "Dokumente",
  props: {
    fondsdata: {},
  },
  data: () => ({
    error: null,
    notfound: false,
    unauthorized: false,
    servererror: false,
    complianceError401: false,
    complianceError404: false,
    keyVerkaufsprospekt: 1,
    keyNachtrag: 4,
    keyGeschaefstbericht: 15,
  }),
  created() {
    this.$store.dispatch('resetStatus');
  },
  computed: {
    dokumenteFields() {
      return [
        { key: 'datum', label: '', tdClass: ['text-left', 'col-40-percent'], },
        { key: 'anzeigename', label: '', tdClass: ['text-left', 'col-60-percent'], },
      ]
    },
    hasFileError() {
      return this.$store.getters["hasFileError"];
    },
    fileError() {
      return this.$store.getters["fileRetrievalStatus"];
    },
  },
  methods: {
    getDownloadLink( dateiObject ) {
      var href = "";
      for(let i = 0; i < dateiObject.links.length; i++){
        var link = dateiObject.links[i];
        if ( link.rel === 'download' ) {
          href = link.href;
        }
      }
      return href;
    },
    async download( dateiObject ) {

        let token = this.$store.getters['auth/getToken']
        if(token === '' || token === undefined){
          throw new Error('Missing Token!')
        }

        var config = {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/x-www-form-urlencoded',
            'XClacksOverhead': 'GNU Terry Pratchett',
            'X-API-KEY': this.$store.getters.getAllEnvis.API_KEY,
          },
        }

        return axios.get( this.$store.getters.getAllEnvis.API_DOWNLOADS_CHECK, config)

          .then( () => {
            this.$store.dispatch('complianceDownloads/getComplianceDownloads', this.$route.params.id).then( () => {
              this.complianceError401 = false;
              this.complianceError404 = false;

              this.$store.dispatch('downloadFileWithToken', {
                filename: dateiObject.datei.downloadname,
                href: this.getDownloadLink( dateiObject ),
                subsite: this.$store.getters.getSubsite,
              } );

            }, error => {
              if (error.response.status === 401) {
                this.complianceError401 = true;

              } else if ( error.response.status === 403 ) {

                this.$store.dispatch('complianceDownloads/setDownloadPayload', {
                  filename: dateiObject.datei.downloadname,
                  href: this.getDownloadLink( dateiObject ),
                  subsite: this.$store.getters.getSubsite,
                } );

                this.$store.dispatch('complianceDownloads/setDownloadTab', {
                  selectedTab: 3,
                } );

                const tid = this.$route.params.id;

                router.push({name: 'ComplianceDownloads', params: {client: this.$store.getters['getSubsite']}, query: {tid}});

              } else if ( error.response.status === 404 ) {
                // Unbekannte Tranche
                this.complianceError404 = true;
              }
              return error;
            })
              .catch(error => {
                console.log(error);
            })

          }, error => {
            if (error.response.status === 403) {
              router.push({name: 'ComplianceLimit', params: {client: this.$store.getters['getSubsite']}});
            }
            console.log(error);
            this.error = error;
            return error;
          }).catch(error => {
            console.log( error );
          })



    },
  },
}
</script>

<style scoped>

</style>