<template>
  <div>
    <div v-if="unauthorized" class="pt-4">
      <div class="alert alert-warning" role="alert">
        <p>Sie haben hierfür keine Berechtigung.</p>
      </div>
    </div>
    <div v-else-if="notfound" class="pt-4">
      <div class="alert alert-warning" role="alert">
        <p>Dieser Fonds konnte nicht ermittelt werden.</p>
      </div>
    </div>
    <div v-else-if="servererror" class="pt-4">
      <div class="alert alert-danger" role="alert">
        <p>Es ist ein Fehler aufgetreten.</p>
        <p v-if="error" class="fs-7">{{ error }}</p>
      </div>
    </div>
    <div v-else class="pt-4">
      <h4 class="text-left text-uppercase h4-color">Vermittlungen *</h4>
      <b-table id="vermittlungentable" ref="vermittlungentable"
               bordered
               striped
               responsive="xl"
               :isBusy.sync="loading"
               :items="vermittlungenProvider"
               :fields="fields"
               :per-page="perPage"
               :current-page="currentPage"
               :key="rerenderTrigger"
               head-variant="dark"
      >
        <template #cell(vermittlungstag)="data">
          <div>{{ data.item.vermittlungstag }}</div>
        </template>
        
        <template #cell(kurs)="data">
          <div>{{ formatPercent( data.item.kurs ) }}%</div>
        </template>

        <template #cell(volumen)="data">
          <div>{{ formatPrice( data.item.volume ) }} {{fondsdata.currency}}</div>
        </template>

        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong class="ml-2">Die Daten werden geladen...</strong>
          </div>
        </template>
      </b-table>
      
      <b-pagination
          v-model="currentPage"
          :total-rows="vermittlungencount"
          :per-page="perPage"
          aria-controls="vermittlungentable"
          align="right"
          v-if="vermittlungencount > perPage"
      ></b-pagination>
      <div class="text-right fs-7 mb-3">
        Es wurden {{ vermittlungencount }} Vermittlungen zu diesem Fonds gefunden.
      </div>
    </div>

    <h4 class="text-left text-uppercase h4-color mt-3">
        Kursverlauf*
    </h4>
    <div id="chartAnchor"></div>

    <div v-if="!loadingChart" class="trendPeriod buttonWrapper">
      <b-button variant="primary" v-bind:class="[{active : monthspast == '6'}]" :disabled="monthspast == '6'" class="mr-2 mb-2" @click="fetchKursverlaufData(monthspast = 6);scrollMeTo();">6 Monate</b-button>
      <b-button variant="primary" v-bind:class="[{active : monthspast == '12'}]" :disabled="monthspast == '12'" class="mr-2 mb-2" @click="fetchKursverlaufData(monthspast = 12);scrollMeTo();">12 Monate</b-button>
      <b-button variant="primary" v-bind:class="[{active : monthspast == '36'}]" :disabled="monthspast == '36'" class="mr-2 mb-2" @click="fetchKursverlaufData(monthspast = 36);scrollMeTo();">3 Jahre</b-button>
      <b-button variant="primary" v-bind:class="[{active : monthspast == '60'}]" :disabled="monthspast == '60'" class="mr-2 mb-2" @click="fetchKursverlaufData(monthspast = 60);scrollMeTo();">5 Jahre</b-button>

      <apexchart :options="options" :series="series"></apexchart>
    </div>
    <div v-else>
      <b-spinner class="align-middle"></b-spinner>
      <strong class="ml-2">Die Daten werden geladen...</strong>
    </div>

    <div class="text-left fs-7 my-3">
      (*) Bitte beachten Sie die Hinweise gemäß 
      <a 
          target="_blank"
          href="https://www.zweitmarkt.de/service/hinweise-nach-delegierte-verordnung-eu-2017565.html">
        Delegierte Verordnung (EU) 2017/565.
      </a>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import priceMixin from "@/mixins/formatPrice";
import percentMixin from "@/mixins/formatPercent";

export default {
  name: "Statistiken",
  props: {
    fondsdata: null,

    investment: {
      type: Object,
      default() {
        return {}
      }
    },
    untranslated: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    startYearsAgo: {
      type: Number,
      default: undefined
    },
    startDate: {
      type: [String, Number],
      default: undefined
    },
    height: {
      type: [String, Number],
      default: undefined
    },
  },
  data: () => ({
    vermittlungdata: null,
    kursverlauf: [],
    monthspast: 12,
    historicDate: [],
    error: null,
    loading: true,
    loadingChart: true,
    notfound: false,
    unauthorized: false,
    servererror: false,
    currentPage: 1,
    perPage: 10,
    vermittlungencount: 0,
    rerenderTrigger: 0,
  }),
  created() {
    this.fetchKursverlaufData(this.monthspast);
  },
  computed: {
    fields() {
      return [
        { key: 'vermittlungstag', label: 'Vermittlungstag', tdClass: 'text-left', },
        { key: 'kurs', label: 'Kurs', tdClass: 'text-left', },
        { key: 'volumen', label: 'Volumen', tdClass: 'text-left', },
      ]
    },
    series() {
       const getData = key => this.kursverlauf.map(line => line[key])

      return [{
        name: 'Vermittlungsvolumen in Fondswährung',
        type: 'column',
        data: getData('volume')
      }, {
        name: 'Vermittlungskurs in Prozent',
        type: 'line',
        data: getData('kurs')
      }]
    },
    options() {
      return  {
        colors: [
          '#ffc107',
          '#17a2b8'
        ],
        yaxis: [{
          title: {
            text: 'Volumen',
          },
          labels: {
              formatter: function (value) {
                const formatter = new Intl.NumberFormat('de-DE', {
                  minimumFractionDigits: 2
                })
                return formatter.format(value);
              }
          },
        }, {
          opposite: true,
          title: {
            text: 'Kurs in %'
          },
          labels: {
            formatter: function (value) {
              const parts = (+value).toFixed(2).split(".");
              return parts[0] + "," + parts[1];
            }
          },
        }], label: {
          formatter: function (value) {
            value.formatMoney(2, ".", ",");
            return value;
          }
        },
        dataLabels: {
          enabled: false
        },
        chart: {
          type: 'area',
          toolbar: {
            tools: {
              reset: '<i class="fa fa-undo-alt reset-icon"></i>',
              // download: '<i class="fa fa-download download-icon"></i>',
            }
          },
        },
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              height: 300,
            }
          }
        }],
        stroke: {
          type: 'filled',
          curve: 'straight',
          width: 2,
        },
        markers: {
          size: 3,
        },
        fill: {
          opacity: 0.2,
          type: 'solid',
        },
        labels: this.kursverlauf.map(line => line['vermittlungstag'])
      }
    },
  },
  methods: {
    forceRerender() {
      this.rerenderTrigger += 1;
    },
    vermittlungenProvider( ctx, callback ) {
      this.loading = true;
      const config = {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
            'XClacksOverhead': 'GNU Terry Pratchett',
            'X-API-KEY': this.$store.getters.getAllEnvis.API_KEY,
          },
        params: {
          trancheId: this.$route.params.id,
          page: ctx.currentPage - 1,
          size: ctx.perPage,
        }
      }

      axios.get( this.$store.getters.getAllEnvis.API_PRODUKTE_ZWM_TRANCHE_VERMITTLUNGEN + this.$route.params.id, config )
          .then( response => {
            this.vermittlungencount = response.data.vermittlungencount;
            this.loading = false;
            callback( response.data.daten );
          },
          error => {
          if (error.response.status === 401) {
            this.unauthorized = true;
          }
          else if ( error.response.status === 404 ) {
            this.notfound = true;
          }
          else {
            this.servererror = true;
          }
          this.loading = false;
          this.error = error;

          callback( [] );
        } )
        .catch(error => {
          console.log(error);
          this.error = error;
          this.loading = false;
          callback( [] );
        })
    },
    fetchKursverlaufData(monthspast = 6) {
      this.loadingChart = true;
      const trancheId = this.$route.params.id
      const config = {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
          'XClacksOverhead': 'GNU Terry Pratchett',
          'X-API-KEY': this.$store.getters.getAllEnvis.API_KEY,
        },
        params: {
          monthspast,
        }
      }

      axios.get( this.$store.getters.getAllEnvis.API_PRODUKTE_ZWM_TRANCHE_VERMITTLUNGEN_HISTORIC + trancheId, config)
          .then( response => {
            this.kursverlauf = response.data.daten;
            this.kursverlauf.reverse();
            this.loadingChart = false;
          })
          .catch(error => {
            if (error.response.status === 401) {
              this.unauthorized = true;
            }
            else if ( error.response.status === 404 ) {
              this.notfound = true;
            }
            else {
              this.servererror = true;
            }

            console.log(error);
            this.error = error;
            this.loadingChart = false;
          })
    },
    scrollMeTo() {
      setTimeout (function() {
        const anchorElement = document.getElementById("chartAnchor")
        anchorElement.scrollIntoView({behavior: "smooth"})
      }, 2000)
    }
  },
  mixins: [priceMixin,percentMixin],
}

</script>

<style scoped>

</style>