<template>
  <div class="three-button-row w-100 p-3 text-center h-100 d-inline-block py-3" style="background-color: white;">

    <b-modal id="addWatchConfirm"
             title="Beobachtung hinzugefügt"
             header-bg-variant="dark"
             :hide-footer="isWatchLoading">
      <template #modal-footer="{ ok }">
        <b-button variant="primary" @click="ok()" class="btn btn-default btn-form">OK</b-button>
      </template>
      <div class="text-center my-2" v-if="isWatchLoading">
        <b-spinner class="align-middle"></b-spinner>
        <strong class="ml-2">Die Beobachtung wird gespeichert...</strong>
      </div>
      <div v-else>
        <p class="my-4" v-if="hasWatchError">Beim Speichern ist ein Fehler aufgetreten.</p>
        <div class="my-4" v-else>
          <p>Sie beobachten nun <strong>{{ fondsname }}</strong> und erhalten E-Mails mit aktuellen Handelsinformationen.</p>
          <p>Sie können nun auch die Fondsliste nach Ihren beobachteten Fonds filtern.</p>
        </div>
      </div>
    </b-modal>

    <b-modal id="deleteWatchConfirm"
             title="Beobachtung entfernt"
             header-bg-variant="dark"
             :hide-footer="isWatchLoading">
      <template #modal-footer="{ ok }">
        <b-button variant="primary" @click="ok()" class="btn btn-default btn-form">OK</b-button>
      </template>
      <div class="text-center my-2" v-if="isWatchLoading">
        <b-spinner class="align-middle"></b-spinner>
        <strong class="ml-2">Die Beobachtung wird gelöscht...</strong>
      </div>
      <div v-else>
        <p class="my-4" v-if="hasWatchError">Beim Speichern ist ein Fehler aufgetreten.</p>
        <p class="my-4" v-else>
          Sie beobachten <strong>{{ fondsname }}</strong> nun nicht mehr und erhalten keine E-Mails mit Handelsinformationen.
        </p>
      </div>
    </b-modal>
    
    <div v-if="this.handelAusgesetzt">
      <div class="font-weight-bold">Dieser Fonds ist vom Handel ausgesetzt.</div>
    </div>
    <div v-else>

      <div class="btn-group" role="group" aria-label="Aktionen">
        <a @click="wizardLink(`${trancheId}`, 'VERKAUF')" class="btn-trade pointer px-2 btn-sell"><span v-if="!isMeinHandel" style="top:0;">V</span></a>
        <a @click="wizardLink(`${trancheId}`, 'VERKAUF')" style="padding-top:0.75rem !important;" class="token-link pl-0 pr-2">
          <span class="pl-2 pr-2 pt-1">{{verkaufWord}}</span>
        </a>
      </div>

      <div class="btn-group" role="group" aria-label="Aktionen">
        <a @click="wizardLink(`${trancheId}`, 'KAUF')" class="btn-trade pointer px-2 btn-buy"><span v-if="!isMeinHandel" style="top:0;">K</span></a>
        <a @click="wizardLink(`${trancheId}`, 'KAUF')" style="padding-top:0.75rem !important;" class="token-link pl-0">
          <span class="pl-2 pr-2 pt-1">{{kaufWord}}</span>
        </a>
      </div>

      <div class="btn-group" role="group" aria-label="Aktionen" :id="submitCount" v-if="hasToken()">
        <a class="token-link pointer" v-if="hasToken() && !isTrancheWatched( `${trancheId}` )"
           @click="watchTranche( `${trancheId}` )">
          <i class="fa fa-eye fa-2x" aria-hidden="true" :title="beobachtenWord"></i>
          <span class="pl-2 pr-2 pt-1">{{beobachtenWord}}</span>
        </a>
        <a class="token-link pointer" v-if="hasToken() && isTrancheWatched( `${trancheId}` )"
           @click="unwatchTranche( `${trancheId}` )">
          <i class="fas fa-eye-slash fa-2x" aria-hidden="true" :title="beobachtenDeleteWord"></i>
          <span class="pl-2 pr-2 pt-1">{{beobachtenDeleteWord}}</span>
        </a>
        
      </div>
    </div>
  </div>
</template>

<script>
import router from "../../router";

export default {
  name: "FondsAktionen",
  components: {},
  props: {
    trancheId: {
      type: Number,
      default: null,
    },
    fondsname: {
      type: String,
      default: '',
    },
    handelAusgesetzt: {
      type: Boolean,
      default: false,
    }
  },
  data: () => ({
    submitCount: 0,
  }),
  computed: {
    kaufWord() { return "Kaufen" },
    verkaufWord() { return "Verkaufen" },
    beobachtenWord() { return "Beobachten" },
    beobachtenDeleteWord() { return "Beobachtung löschen" },
    token() {
      return this.$store.getters["auth/getToken"]
    },
    client() {
      return this.$route?.params?.client
    },
    isMeinHandel() {
      return this.client === 'meinhandel';
    },
    isWatchLoading() {
      return this.$store.getters["watches/isWaiting"];
    },
    hasWatchError() {
      var watchStatus = this.$store.getters["watches/watchesStatus"];
      return !(watchStatus === undefined || watchStatus === null || watchStatus === '' || watchStatus === "" )
    },
  },
  methods: {
    hasToken() {
      return this.$store.getters["auth/hasToken"];
    },
    wizardLink( tid, type) {
      if(this.token) {
        router.push({name: 'AuftragsVerwaltung', params: {client: this.$store.getters['getSubsite']}, query: {auftragType: type, tid: tid}});
      } else {
        const a = 'auftragsverwaltung';
        const b = type;
        const c = tid;
        router.push({name: 'login', params: {client: this.$store.getters['getSubsite']}, query: { a, b, c }});
      }
    },
    isTrancheWatched( trancheId ) {
      return this.$store.getters["watches/isWatched"](trancheId);
    },
    forceRerender() {
      this.submitCount += 1;
    },
    watchTranche( trancheId ) {
      const payload = {
        trancheId: trancheId,
      }
      this.$store.dispatch("watches/addWatch", payload );
      this.$bvModal.show( 'addWatchConfirm' );
      this.forceRerender();
    },
    unwatchTranche( trancheId ) {
      const payload = {
        trancheId: trancheId,
      }
      this.$store.dispatch("watches/removeWatch", payload );
      this.$bvModal.show( 'deleteWatchConfirm' );
      this.forceRerender();
    },
    fetchWatches() {
      if ( this.hasToken() ) {
        this.$store.dispatch('watches/loadWatches')
      }
    },
  },
  created() {
    this.fetchWatches();
  },
  mounted() {

    this.$root.$on('bv::modal::hidden', (bvEvent, modalId) => {
      if ( "deleteWatchConfirm" === modalId ) {
        this.fetchWatches();
        this.forceRerender();
      }
    })

  }
}
</script>

<style scoped>

</style>