<template>
  <div v-if="!fondsdata">
    <p>Keine Daten vorhanden</p> 
  </div>  
  <div v-else class="text-left pb-3 pt-4">
    <h4 class="text-uppercase h4-color">Fondsgrunddaten</h4>
    <table class="table table-striped table-bordered mb-5">
      <colgroup>
        <col style="width: 40%"/>
        <col style="width: 60%"/>
      </colgroup>
      <tbody>
        <tr>
          <td class="font-weight-bold">Anbieter</td>
          <td>{{ fondsdata.anbietername }}</td>
        </tr>
        <tr v-if="fondsdata.kvg">
          <td class="font-weight-bold">Kapitalverwaltungsgesellschaft</td>
          <td>{{ fondsdata.kvg }}</td>
        </tr>
        <tr v-if="fondsdata.assetmanager">
          <td class="font-weight-bold">Assetmanager</td>
          <td>{{ fondsdata.assetmanager }}</td>
        </tr>
        <tr v-if="fondsdata.verwahrstelle">
          <td class="font-weight-bold">Verwahrstelle</td>
          <td>{{ fondsdata.verwahrstelle }}</td>
        </tr>
        <tr>
          <td class="font-weight-bold">Fonds</td>
          <td>{{ fondsdata.fondsname }}</td>
        </tr>
        <tr v-if="fondsdata.sachwertinvestmenttyp">
          <td class="font-weight-bold">Sachwertinvestment-Typ</td>
          <td>{{ fondsdata.sachwertinvestmenttyp }}</td>
        </tr>
        <tr v-if="fondsdata.risikostreuung">
          <td class="font-weight-bold">Risikostreuung</td>
          <td>{{ fondsdata.risikostreuung }}</td>
        </tr>
        <tr v-if="fondsdata.firma">
          <td class="font-weight-bold">Firma</td>
          <td>{{ fondsdata.firma }}</td>
        </tr>
        <tr v-if="fondsdata.rechtsform">
          <td class="font-weight-bold">Rechtsform</td>
          <td>{{ fondsdata.rechtsform }}</td>
        </tr>
        <tr>
          <td class="font-weight-bold">Fondssegment</td>
          <td>{{ fondsdata.assetklasse }}</td>
        </tr>
        <tr v-if="fondsdata.fondsgegenstand">
          <td class="font-weight-bold">Beteiligungsgegenstand</td>
          <td>{{ fondsdata.fondsgegenstand }}</td>
        </tr>
        <tr v-if="fondsdata.trancheart">
          <td class="font-weight-bold">Art der Tranche</td>
          <td>{{ fondsdata.trancheart }}</td>
        </tr>
        <tr>
          <td class="font-weight-bold">Währung</td>
          <td>{{ fondsdata.currency }}</td>
        </tr>
        <tr v-if="fondsdata.einkunftsarten">
          <td class="font-weight-bold">Haupteinkunftsarten</td>
          <td>{{ fondsdata.einkunftsarten }}</td>
        </tr>
        
      </tbody>
    </table>
    
    <table class="table table-striped table-bordered">
      <colgroup>
        <col style="width: 40%;"/>
        <col style="width: 60%"/>
      </colgroup>
      <tbody>
        <tr>
          <td class="font-weight-bold">Gesamtinvestition (Ist)*</td>
          <td>{{ formatPriceNoCents( fondsdata.gik ) }} {{ fondsdata.currency }}</td>
        </tr>
        <tr>
          <td class="font-weight-bold">Eigenkapital (Ist)*</td>
          <td>{{ formatPriceNoCents( fondsdata.gek ) }} {{ fondsdata.currency }}</td>
        </tr>
        <tr>
          <td class="font-weight-bold">Fremdkapital Emissionsjahr (Ist)*</td>
          <td>{{ formatPriceNoCents( fondsdata.fk ) }} {{ fondsdata.currency }}</td>
        </tr>
        <tr v-if="hasToken">
          <td class="font-weight-bold">Fremdkapitalquote Ist*</td>
          <td>{{ formatPercent( fondsdata.fkQuote ) }}%</td>
        </tr>
      </tbody>
    </table>
    <p>(*) Bis Ende der Platzierungsphase.</p>
  </div>
  
</template>

<script>
import priceMixin from "@/mixins/formatPrice";
import percentMixin from "@/mixins/formatPercent";

export default {
  name: "Fondsgrunddaten",
  props: {
    fondsdata: {},
  },
  computed: {
    gegenstandFields() {
      return [
        { key: 'gegenstand', label: '', tdClass: 'col-11', }
      ]
    },
    ausschuettungenFields() {
      return [
        { key: 'jahr', label: '', tdClass: 'col-40-percent', },
        { key: 'wert', label: '', tdClass: 'col-60-percent', }
      ]
    },
    hasToken() {
      return this.$store.getters["auth/hasToken"];
    }
  },
  mixins: [priceMixin,percentMixin],
}
</script>

<style scoped>
</style>