<template>
  <div>
    <b-table striped :items="gegenstandItems" :fields="fields" thead-class="hidden-header">
      <template #cell(keyvalue)="data">
        <div class="text-left font-weight-bold">{{ mapKey( data.item.key ) }}</div>
      </template>
      <template #cell(valuedisplay)="data">
        <div class="text-left">{{ data.item.value }}</div>
      </template>
    </b-table>
  </div>  
</template>

<script>
export default {
  name: "FondsObjekt",
  computed: {
    fields() {
      return [
        { key: 'keyvalue', label: '', tdClass: 'col-40-percent' },
        { key: 'valuedisplay', label: '', tdClass: 'col-60-percent' },
      ]
    },  
  },
  props: {
    gegenstandItems: {},
  },
  methods: {
    mapKey( keyValue ) {
      if ( 'FondsObjektBezeichung' === keyValue ) {
        return 'Gegenstand';  
      }
      if ( 'KapazitätTEU' === keyValue ) {
        return 'Kapazität TEU'
      } 
      if ( 'KapazitätTDW' === keyValue ) {
        return 'Kapazität TDW'
      }
      if ( 'AnzahlAnlagen' === keyValue ) {
        return 'Anzahl Anlagen'
      }
      
      return keyValue;
    },
  }
}
</script>

<style scoped>
</style>