<template>
  <div>
    <h4 class="text-uppercase text-left h4-color pt-4">Basisdaten</h4>
    <div v-if="unauthorized">
      <div class="alert alert-warning" role="alert">
        <p>Sie haben hierfür keine Berechtigung.</p>
      </div>
    </div>
    <div v-else-if="notfound">
      <div class="alert alert-warning" role="alert">
        <p>Dieser Fonds konnte nicht ermittelt werden.</p>
      </div>
    </div>
    <div v-else-if="servererror">
      <div class="alert alert-danger" role="alert">
        <p>Es ist ein Fehler aufgetreten.</p>
        <p v-if="error" class="fs-7">{{ error }}</p>
      </div>
    </div>
    <div v-else>
      <b-table id="auftraegetable" ref="auftraegetable"
               bordered
               striped
               responsive="xl"
               :isBusy.sync="loading"
               :items="orderbuchProvider"
               :fields="fields"
               :per-page="perPage"
               :current-page="currentPage"
               :key="rerenderTrigger"
               head-variant="dark"
      >

        <template #head(auftragsart)="">
          <table-header-sort-element
              :sortprop="getSortObject( 'auftragType' ).key"
              :sortdir="getSortObject( 'auftragType' ).sortdir"
              v-on:sort-clicked="onSort"
          >
            Auftragsart
          </table-header-sort-element>
        </template>

        <template #head(nominale)="">
          <table-header-sort-element
              :sortprop="getSortObject( 'nominale' ).key"
              :sortdir="getSortObject( 'nominale' ).sortdir"
              v-on:sort-clicked="onSort"
          >
            Nominale
          </table-header-sort-element>
        </template>

        <template #head(limit)="">
          <table-header-sort-element
              :sortprop="getSortObject( 'limit' ).key"
              :sortdir="getSortObject( 'limit' ).sortdir"
              v-on:sort-clicked="onSort"
          >
            Limit
          </table-header-sort-element>
        </template>

        <template #head(teilausfuehrbar)="">
          <table-header-sort-element
              :sortprop="getSortObject( 'teilausfuehrbar' ).key"
              :sortdir="getSortObject( 'teilausfuehrbar' ).sortdir"
              v-on:sort-clicked="onSort"
          >
            T/A
          </table-header-sort-element>
        </template>
        
        
        <template #cell(auftragsart)="data">
          <span>{{data.item.auftragsart}}</span>
        </template>

        <template #cell(nominale)="data">
          <span>{{ formatPrice( data.item.nominale.betrag ) }} {{data.item.nominale.waehrung}}</span>
        </template>

        <template #cell(limit)="data">
          <span>{{ formatPercent( data.item.limit ) }}%</span>
        </template>

        <template #cell(teilausfuehrbar)="data">
          <span v-if="data.item.teilausfuehrbar">
            <i class="fas fa-check-circle"></i>  
          </span>
        </template>

        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong class="ml-2">Die Daten werden geladen...</strong>
          </div>
        </template>
      </b-table>

      <b-pagination
          v-model="currentPage"
          :total-rows="auftraegecount"
          :per-page="perPage"
          aria-controls="auftraegetable"
          align="right"
          v-if="auftraegecount > perPage"
      ></b-pagination>
      <div class="text-right fs-7 mb-3">
        Es wurden {{ auftraegecount }} Aufträge zu diesem Fonds gefunden.
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import percentMixin from "@/mixins/formatPercent";
import priceMixin from "@/mixins/formatPrice";
import TableHeaderSortElement from "@/components/sorting/TableHeaderSortElement";

export default {
  name: "Aufträge",
  components: {TableHeaderSortElement},
  data: () => ({
    auftraegedata: null,
    error: null,
    loading: true,
    notfound: false,
    unauthorized: false,
    servererror: false,
    currentPage: 1,
    perPage: 25,
    auftraegecount: 0,
    rerenderTrigger: 0,
    form: {
      sortproperty: 'limit',
      sortdirection: 'desc',
    },
    sortoptions: [
      { key: 'limit', name: 'limit', sortdir: 'desc' },
      { key: 'nominale', name: 'nominale', sortdir: 'none' },
      { key: 'auftragType', name: 'auftrag_type', sortdir: 'none' },
      { key: 'teilausfuehrbar', name: 'teilausfuehrbar', sortdir: 'none' },
    ],
  }),
  computed: {
    fields() {
      return [
        { key: 'auftragsart', label: 'Auftragsart', tdClass: 'text-left', thClass: 'text-left', },
        { key: 'nominale', label: 'Nominale', tdClass: 'text-left', thClass: 'text-left', },
        { key: 'limit', label: 'Limit', tdClass: 'text-left', thClass: 'text-left', },
        { key: 'teilausfuehrbar', label: 'T/A', headerTitle: 'Teilausführbar', tdClass: '', },
      ]
    },
  },
  methods: {
    forceRerender() {
      this.rerenderTrigger += 1;
    },
    onSubmit( event ) {
      if ( event ) { event.preventDefault() }
      this.currentPage = 1;
      this.forceRerender();
    },
    getSortObject( sortkey ) {
      for( var sortopt of this.sortoptions ) {
        if( sortopt.key === sortkey ) {
          return sortopt;
        }
      }
      return [];
    },
    onSort: function( sortpropkey, sortdir ) {
      let newSortDir = sortdir !== 'none' ? sortdir : 'asc';
      var sortopt = this.getSortObject( sortpropkey );
      this.form.sortproperty = sortopt.name;
      this.form.sortdirection = sortdir;
      for( var s of this.sortoptions ) {
        if( s.key === sortpropkey ) {
          s.sortdir = newSortDir;
        }
        else {
          s.sortdir = 'none';
        }
      }
      let event = null;
      this.onSubmit( event );
    },
    orderbuchProvider( ctx, callback ) {
      this.loading = true;
      const token = this.$store.getters["auth/getToken"];
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          'Accept': 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
          'XClacksOverhead': 'GNU Terry Pratchett',
          'X-API-KEY': this.$store.getters.getAllEnvis.API_KEY,
        },
        params: {
          trancheId: this.$route.params.id,
          page: ctx.currentPage - 1,
          size: ctx.perPage,
          sort: this.form.sortproperty ? this.form.sortproperty + ',' + this.form.sortdirection : '',
        }
      }
      axios.get( this.$store.getters.getAllEnvis.API_TRANCHE_ORDERBUCH.replace( '{id}', this.$route.params.id ), config )
          .then( response => {
            this.auftraegecount = response.data.auftraegecount;
            this.loading = false;
            callback( response.data.content );
          }, error => {
            if (error.response.status === 401) {
              this.unauthorized = true;
            }
            else if ( error.response.status === 404 ) {
              this.notfound = true;
            }
            else {
              this.servererror = true;
            }
            this.loading = false;
            this.error = error;
            callback( [] );
          } )
          .catch(error => {
            console.log(error);
            this.error = error;
            this.loading = false;
            callback( [] );
          })
    },
  },
  mixins: [percentMixin, priceMixin],
}

</script>

<style scoped>

</style>