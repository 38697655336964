<template>
  <div>
    <div v-if="unauthorized">
      <div class="alert alert-warning" role="alert">
        <p>Sie haben hierfür keine Berechtigung.</p>
      </div>
    </div>
    <div v-else-if="notfound">
      <div class="alert alert-warning" role="alert">
        <p>Dieser Fonds konnte nicht ermittelt werden.</p>
      </div>
    </div>
    <div v-else-if="servererror">
      <div class="alert alert-danger" role="alert">
        <p>Es ist ein Fehler aufgetreten.</p>
        <p v-if="error" class="fs-7">{{ error }}</p>
      </div>
    </div>
    <div v-else>

      <div class="row py-3">
        <div class="col-sm-12">

          <div>
            <b-col class="text-left pl-0 pr-0">
              <b-collapse v-model="sortFilterBar">
                <b-button class="sort-tile mb-3" @click="toggleSortLayer">
                  <i class="fa fa-sort-alt"></i> Sortieren<br />
                  <span class="currentSort">
                  (Aktuell: {{ sortPropDisplay() }}
                  <i class="fas fa-long-arrow-alt-down" v-if="currentSortDirIsDesc()"></i>
                  <i class="fas fa-long-arrow-alt-up" v-else></i>
                    <!-- a class="ml-2" @click="resetSort()"><i class="fas fa-times"></i></a -->)
                </span>
                </b-button>
              </b-collapse>
            </b-col>

            <b-collapse id="mobile-sort" class="pt-3" v-model="sortToggle">
              <b-row>
                <b-col class="text-left">
                  <ul>
                    <li v-on:click="onSort('auftragType');toggleSortFilterBar()">
                      <i class="fa fa-sort-alt"></i> Auftragsart
                    </li>
                    <li v-on:click="onSort('nominale');toggleSortFilterBar()">
                      <i class="fa fa-sort-alt"></i> Nominale
                    </li>
                    <li v-on:click="onSort('limit');toggleSortFilterBar()">
                      <i class="fa fa-sort-alt"></i> Limit
                    </li>
                    <li v-on:click="onSort('teilausfuehrbar');toggleSortFilterBar()">
                      <i class="fa fa-sort-alt"></i> Teilausfuehrbar
                    </li>
                  </ul>
                </b-col>
              </b-row>
            </b-collapse>

            <b-table id="auftraegetable" ref="auftraegetable"
                     class="mobileTable"
                     bordered
                     stacked
                     responsive="xl"
                     :isBusy.sync="loading"
                     :items="orderbuchProvider"
                     :fields="fields"
                     :per-page="perPage"
                     :current-page="currentPage"
                     :key="rerenderTrigger"
                     head-variant="dark"
            >
              <template #cell(auftragsart)="data">
                <table class="innerTable">
                  <tr>
                    <td class="text-left label1">
                      Auftragsart
                    </td>
                    <td class="label2">
                      <span>{{data.item.auftragsart}}</span>
                    </td>
                  </tr>
                </table>
              </template>

              <template #cell(nominale)="data">
                <table class="innerTable">
                  <tr>
                    <td class="text-left label1">
                      Nominale
                    </td>
                    <td class="label2">
                      <span>{{ formatPrice( data.item.nominale.betrag ) }} {{data.item.nominale.waehrung}}</span>
                    </td>
                  </tr>
                </table>
              </template>

              <template #cell(limit)="data">
                <table class="innerTable">
                  <tr>
                    <td class="text-left label1">
                      Limit
                    </td>
                    <td class="label2">
                      <span>{{ formatPercent( data.item.limit ) }}%</span>
                    </td>
                  </tr>
                </table>
              </template>

              <template #cell(teilausfuehrbar)="data">
                <table class="innerTable">
                  <tr>
                    <td class="text-left label1">
                      <span title="Teilausführbar">T/A</span>
                    </td>
                    <td class="label2">
                      <span v-if="data.item.teilausfuehrbar">
                        <i class="fas fa-check-circle"></i>
                      </span>
                    </td>
                  </tr>
                </table>
              </template>

              <template #cell(empty)="">
                <table class="innerTable">
                  <tr>
                    <td>&nbsp;</td>
                  </tr>
                </table>
              </template>

              <template #table-busy>
                <div class="text-center my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong class="ml-2">Die Daten werden geladen...</strong>
                </div>
              </template>
            </b-table>

            <b-pagination
                v-model="currentPage"
                :total-rows="auftraegecount"
                :per-page="perPage"
                aria-controls="auftraegetable"
                align="right"
                v-if="auftraegecount > perPage"
            ></b-pagination>
          </div>

          <div class="text-right fs-7 mb-3">
            Es wurden {{ auftraegecount }} Aufträge zu diesem Fonds gefunden.
          </div>

        </div>
      </div>

    </div>
  </div>
</template>

<script>
import axios from "axios";
import percentMixin from "@/mixins/formatPercent";
import priceMixin from "@/mixins/formatPrice";

export default {
  name: "Aufträge",
  components: {},
  data: () => ({
    auftraegedata: null,
    error: null,
    loading: true,
    notfound: false,
    unauthorized: false,
    servererror: false,
    currentPage: 1,
    perPage: 25,
    auftraegecount: 0,
    rerenderTrigger: 0,
    form: {
      sortproperty: 'limit',
      sortdirection: 'desc',
    },
    sortFilterBar: true,
    sortToggle: false,
    sortoptions: [
      { key: 'limit', name: 'limit', sortdir: 'desc', display: 'Limit' },
      { key: 'nominale', name: 'nominale', sortdir: 'none', display: 'Nominale' },
      { key: 'auftragType', name: 'auftrag_type', sortdir: 'none', display: 'Auftragsart' },
      { key: 'teilausfuehrbar', name: 'teilausfuehrbar', sortdir: 'none', display: 'T/A' },
    ],
  }),
  computed: {
    fields() {
      return [
        { key: 'auftragsart', label: 'Auftragsart', tdClass: 'mobileRow', },
        { key: 'nominale', label: 'Nominale', tdClass: 'mobileRow', },
        { key: 'limit', label: 'Limit', tdClass: 'mobileRow', },
        { key: 'teilausfuehrbar', label: 'T/A', tdClass: 'mobileRow', },
        { key: 'empty', label: '', tdClass: 'mobileRow' }
      ]
    },
  },
  methods: {
    sortPropDisplay() {
      for( var sortopt of this.sortoptions ) {
        if( sortopt.sortdir !== 'none' ) {
          return sortopt.display;
        }
      }
      return 'Limit';
    },
    currentSortDirIsDesc() {
      var currentSortDir = 'desc';
      for( var sortopt of this.sortoptions ) {
        if( sortopt.sortdir !== 'none' ) {
          currentSortDir = sortopt.sortdir;
        }
      }
      return currentSortDir === 'desc';
    },
    forceRerender() {
      this.rerenderTrigger += 1;
    },
    onSubmit( event ) {
      if ( event ) { event.preventDefault() }
      this.currentPage = 1;
      this.forceRerender();
    },
    getSortObject( sortkey ) {
      for( var sortopt of this.sortoptions ) {
        if( sortopt.key === sortkey ) {
          return sortopt;
        }
      }
      return [];
    },
    onSort: function( sortpropkey ) {
      var sortopt = this.getSortObject( sortpropkey );
      // Get current sortdir and then toggle
      if ( 'none' === sortopt.sortdir || 'desc' === sortopt.sortdir ) {
        sortopt.sortdir = 'asc';
      }
      else if ( 'asc' === sortopt.sortdir ) {
        sortopt.sortdir = 'desc';
      }

      var newSort = sortopt.sortdir;

      // reset all others
      for( var sortopt2 of this.sortoptions ) {
        if( sortopt2.key === sortpropkey ) {
          // do nothing;
        }
        else {
          sortopt2.sortdir = 'none';
        }
      }

      this.form.sortproperty = sortpropkey;
      this.form.sortdirection = newSort;

      let event = null;
      this.onSubmit( event );
    },
    orderbuchProvider( ctx, callback ) {
      this.loading = true;
      const token = this.$store.getters["auth/getToken"];
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          'Accept': 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
          'XClacksOverhead': 'GNU Terry Pratchett',
          'X-API-KEY': this.$store.getters.getAllEnvis.API_KEY,
        },
        params: {
          trancheId: this.$route.params.id,
          page: ctx.currentPage - 1,
          size: ctx.perPage,
          sort: this.form.sortproperty ? this.form.sortproperty + ',' + this.form.sortdirection : '',
        }
      }
      axios.get( this.$store.getters.getAllEnvis.API_TRANCHE_ORDERBUCH.replace( '{id}', this.$route.params.id ), config )
          .then( response => {
            this.auftraegecount = response.data.auftraegecount;
            this.loading = false;
            callback( response.data.content );
          }, error => {
            if (error.response.status === 401) {
              this.unauthorized = true;
            }
            else if ( error.response.status === 404 ) {
              this.notfound = true;
            }
            else {
              this.servererror = true;
            }
            this.loading = false;
            this.error = error;
            callback( [] );
          } )
          .catch(error => {
            console.log(error);
            this.error = error;
            this.loading = false;
            callback( [] );
          })
    },
    toggleSortFilterBar() {
      if(!this.sortFilterBar) {
        this.sortFilterBar = true;
      }

      if(this.sortToggle) {
        this.sortToggle = false;
      }
    },
    toggleSortLayer() {
      if(this.sortToggle) {
        this.sortToggle = false;
        return;
      }

      if(this.filterToggle) {
        this.filterToggle = false;
      }

      if(this.sortFilterBar) {
        this.sortFilterBar = false;
      }

      this.sortToggle = true;
    },
  },
  mixins: [percentMixin, priceMixin],
}

</script>

<style scoped>

</style>