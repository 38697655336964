<template>
  <div>
    <section>
        <Hero>
          <div v-if="loading">
            <div class="text-center my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong class="ml-2">Die Daten werden geladen...</strong>
            </div>
          </div>
          <div v-else-if="unauthorized">
            <div class="alert alert-warning" role="alert">
              <p>Sie haben hierfür keine Berechtigung.</p>
            </div>
          </div>
          <div v-else-if="notfound">
            <div class="alert alert-warning" role="alert">
              <p>Dieser Fonds konnte nicht ermittelt werden bzw. Sie haben keinen Zugriff auf diesen Fonds.</p>
            </div>
          </div>
          <div v-else-if="servererror">
            <div class="alert alert-danger" role="alert">
              <p>Es ist ein Fehler aufgetreten.</p>
              <p v-if="error" class="fs-7">{{ error }}</p>
            </div>
          </div>
          <div v-else>
            <div class="container">
              <HeadText :titletext="this.getFondsName()" :subtext="null"></HeadText>
            </div>
            <div class="container">
              <b-row>
                <b-col md="6" class="no-pad-left-mobile">
                  <trading-data :last-kurs="fondsdata.lastkurs" :last-kurs-date="fondsdata.lastkursdate"></trading-data>  
                </b-col>                                  
                <b-col md="6" class="no-pad-right-mobile">
                  <fonds-aktionen 
                      :tranche-id="fondsdata.id" 
                      :fondsname="this.getFondsName()" 
                      :handel-ausgesetzt="fondsdata.handelausgesetzt">
                  </fonds-aktionen>
                </b-col>
              </b-row>
            </div>
            <div class="container bg-light mt-3">
              <b-tabs content-class="mt-3" nav-class="pt-3" pills>
                <b-tab title="Fondsportrait" :active="activeTab === 0"><Fondsportrait :fondsdata="fondsdata"></Fondsportrait></b-tab>
                <b-tab title="Fondsgrunddaten" :active="activeTab === 1" v-if="hasToken"><Fondsgrunddaten :fondsdata="fondsdata"></Fondsgrunddaten></b-tab>
                <b-tab title="Beteiligungsdaten" :active="activeTab === 2" ><Beteiligungsdaten :fondsdata="fondsdata"></Beteiligungsdaten></b-tab>
                <b-tab title="Dokumente" :active="activeTab === 3" v-if="showDokumente"><FondsKaeuferDokumente :fondsdata="fondsdata"></FondsKaeuferDokumente></b-tab>
                <b-tab title="Dokumente" :active="activeTab === 3" v-if="showDokumenteTeaser"><FondsKaeuferDokumenteTeaser></FondsKaeuferDokumenteTeaser></b-tab>
                <b-tab title="Handelsinformationen" :active="activeTab === 4">
                  <Handelsinformationen :fondsdata="fondsdata"></Handelsinformationen>
                </b-tab>
                <b-tab title="Nachrichten" :active="activeTab === 5" lazy>
                  <FondsNews :trancheId="fondsdata.id"></FondsNews>
                </b-tab>
                <b-tab title="Statistiken" :active="activeTab === 6" lazy>
                  <Statistiken :fondsdata="fondsdata"></Statistiken>
                </b-tab>
                <b-tab title="Aufträge" :active="activeTab === 7" lazy v-if="hasToken && mobileViewport"><FondsOrderbuchMobile></FondsOrderbuchMobile></b-tab>
                <b-tab title="Aufträge" :active="activeTab === 7" lazy v-else-if="hasToken"><FondsOrderbuch></FondsOrderbuch></b-tab>
              </b-tabs>
            </div>
          </div>
        </Hero>
    </section>
  </div>
</template>

<script>
import HeadText from "@/components/HeadText";
import Hero from "@/components/HeroPicture";
import axios from "axios";
import TradingData from "@/components/fondsdetails/TradingData";
import FondsAktionen from "@/components/fondsdetails/FondsAktionen";
import Fondsportrait from "@/components/fondsdetails/Fondsportrait";
import Beteiligungsdaten from "@/components/fondsdetails/Beteiligungsdaten";
import Handelsinformationen from "@/components/fondsdetails/Handelsinformationen";
import FondsNews from "@/components/fondsdetails/FondsNews";
import Statistiken from "@/components/fondsdetails/Statistiken";
import Fondsgrunddaten from "@/components/fondsdetails/Fondsgrunddaten";
import FondsKaeuferDokumente from "@/components/fondsdetails/FondsKaeuferDokumente";
import FondsOrderbuch from "@/components/fondsdetails/FondsOrderbuch";
import FondsOrderbuchMobile from "@/components/fondsdetails/FondsOrderbuchMobile";
import FondsKaeuferDokumenteTeaser from "@/components/fondsdetails/FondsKaeuferDokumenteTeaser";
import {mapGetters} from "vuex";
import router from "../router";

export default {
  name: 'Fondsdetails',
  components: {
    Handelsinformationen, Beteiligungsdaten, Fondsportrait, FondsAktionen, TradingData, Hero, HeadText, FondsNews,
    Statistiken, Fondsgrunddaten, FondsKaeuferDokumente, FondsKaeuferDokumenteTeaser, FondsOrderbuch, FondsOrderbuchMobile
  },
  metaInfo() {
    let rel = 'canonical';
    let currentUrl = window.location.href.replace(this.client, 'meinzweitmarkt');

    return {
      title: `${this.metaTitle}`,
      meta: [
        {
          name: 'description',
          content: `${this.metaDescription}`,
        },
      ],
      link: [
        {
          rel: rel,
          href: currentUrl,
        }
      ]
    }
  },
  created() {
    this.visibilityCheck();
    this.fetchData();
  },
  watch: {
    $route: 'fetchData'
  },
  data: () => ({
    metaTitle: '..:: Zweitmarkt ::..',
    metaDescription: 'Fondsportrait mit Kursen im Zeitverlauf, Beteiligungsdaten und Statistiken. Jetzt informieren!',
    fondsdata: null,
    error: null,
    loading: true,
    notfound: false,
    unauthorized: false,
    servererror: false,
    activeTab: -1,
  }),
  computed: {
    ...mapGetters('complianceDownloads', ['downloadTabPayload']),
    hasToken() {
      return this.$store.getters["auth/hasToken"];
    },
    client() {
      return this.$store.getters['clientConfig/client']
    },
    showDokumente() {
      return this.hasToken && this.fondsdata.isonkaeufermail === true && this.fondsdata.showdokumente === true;
    },
    showDokumenteTeaser() {
      return !this.hasToken && this.fondsdata.isonkaeufermail === true && this.fondsdata.showdokumente === true;
    },
    mobileViewport() {
      return window.innerWidth < this.$store.getters.getMobileBreakpoint;
    },
  },
  methods: {
    visibilityCheck() {
      var config = null;

      if (this.$store.getters["auth/hasToken"]) {
        const token = this.$store.getters['auth/getToken']

        config = {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        }
      } else {
        config = {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        }
      }

      return axios.get( this.$store.getters.getAllEnvis.API_VISIBILITY_CHECK, config)

        .then( response => {
          return response.data;
        }, error => {
          if (error.response.status === 403) {
            router.push({name: 'ComplianceLimit', params: {client: this.$store.getters['getSubsite'], id: this.$route.params.id}});
          }
          console.log(error);
          this.error = error;
          return error;
        }).catch(error => {
          console.log( error );
        })
    },
    getFondsName() {
      let displayname;
      if ( this.fondsdata === null ) {
        return 'Die Daten werden geladen';
      }
      if ( this.fondsdata.fondsname.startsWith( this.fondsdata.anbietername ) ) {
        displayname = this.fondsdata.fondsname;  
      }
      else {
        displayname = this.fondsdata.anbietername + ' ' + this.fondsdata.fondsname;
      }
      if ( this.fondsdata.fondsnamezusatz ) {
        displayname = displayname + ' ' + this.fondsdata.fondsnamezusatz;
      }
      return displayname;
    },
    fetchData() {
      this.loading = true;
      const trancheId = this.$route.params.id
      var config = null;
      var reqParams = null;

      if(this.$store.getters['getSubsite']) {
        reqParams = {
          subsite: this.$store.getters['getSubsite']
        }
      } else {
        reqParams = {
          subsite: window.location.pathname?.split('/')?.[1]
        }
      }

      if (this.$store.getters["auth/hasToken"]) {
        const token = this.$store.getters["auth/getToken"];
        config = {
          headers: {
            Authorization: `Bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
            'XClacksOverhead': 'GNU Terry Pratchett',
            'X-API-KEY': this.$store.getters.getAllEnvis.API_KEY,
          },
          params: reqParams
        };
      } else {
        config = {
          headers: {
            'Accept': 'application/json',
            'XClacksOverhead': 'GNU Terry Pratchett',
            'X-API-KEY': this.$store.getters.getAllEnvis.API_KEY,
          },
          params: reqParams
        };  
      }
      axios.get( this.$store.getters.getAllEnvis.API_PRODUKTE_ZWM_TRANCHE + trancheId, config )
          .then( response => {
            this.fondsdata = response.data;
            this.metaTitle = this.getFondsName() + ' | Fondsbörse';
            this.metaDescription = this.getFondsName() + ': Fondsportrait mit Kursen im Zeitverlauf, Beteiligungsdaten und Statistiken. Jetzt informieren!';
            this.loading = false;
          }, error => {
            if (error.response.status === 401) {
              this.unauthorized = true;
            } else if ( error.response.status === 404 ) {
              this.notfound = true;
            } else if ( error.response.status === 400 ) {
              console.log("This is a 400 error!")
              this.$router.push('/errorPage')
            } else if ( error.response.status === 403 ) {
              // View limit reached
              this.unauthorized = true;
              console.log("View limit reached")
              this.$router.push('/viewlimit')
            } else {
              this.servererror = true;
            }
            this.loading = false;
            this.error = error;
            return error;
          } )
          .catch(error => {
            console.log(error);
            this.error = error;
            this.loading = false;
          })
    },
  },
  mounted() {
    if(this.downloadTabPayload) {
      this.activeTab = this.downloadTabPayload.selectedTab;
    }
    this.$store.dispatch("complianceDownloads/setDownloadTab", 0);
  }
}
</script>

<style lang="scss">
@import "~bootstrap/scss/bootstrap-grid.scss";

@include media-breakpoint-up(md) {
  .no-pad-left-mobile {
    padding-left: 0 !important;
  }

  .no-pad-right-mobile {
    padding-right: 0 !important;
  }
}
</style>