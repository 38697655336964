<template>
  <div v-if="!fondsdata">
    <p>Keine Daten vorhanden</p>
  </div>
  <div v-else class="text-left pb-3 pt-4">
    <h4 class="text-uppercase h4-color">Basisdaten</h4>
    <table class="table table-striped table-bordered mb-5">
      <colgroup>
        <col style="width: 40%"/>
        <col style="width: 60%"/>
      </colgroup>
      <tbody>
      <tr>
        <td class="font-weight-bold">Anbieter</td>
        <td>{{ fondsdata.anbietername }}</td>
      </tr>
      <tr v-if="fondsdata.kvg">
        <td class="font-weight-bold">Kapitalverwaltungsgesellschaft</td>
        <td>{{ fondsdata.kvg }}</td>
      </tr>
      <tr>
        <td class="font-weight-bold">Fondssegment</td>
        <td>{{ fondsdata.assetklasse }}</td>
      </tr>
      <tr v-if="fondsdata.sachwertinvestmenttyp">
        <td class="font-weight-bold">Sachwertinvestment-Typ</td>
        <td>{{ fondsdata.sachwertinvestmenttyp }}</td>
      </tr>
      <tr v-if="fondsdata.fondsdatumemission">
        <td class="font-weight-bold">Datum Verkaufsprospekt</td>
        <td>{{ fondsdata.fondsdatumemission }}</td>
      </tr>
      <tr v-if="fondsdata.trancheart">
        <td class="font-weight-bold">Art der Tranche</td>
        <td>{{ fondsdata.trancheart }}</td>
      </tr>
      <tr v-if="fondsdata.currency !== null">
        <td class="font-weight-bold">Währung</td>
        <td>{{ fondsdata.currency }}</td>
      </tr>
      </tbody>
    </table>

    <h4 class="text-uppercase h4-color">Beteiligungsgegenstand</h4>
    <div v-if="fondsdata.fondsgegenstand !== null">
      <p>{{ fondsdata.fondsgegenstand }}</p>
    </div>
    <div v-else>
      <p>Keine Beteiligungsgegenstände vorhanden.</p>
    </div>
    <b-table
        :items="fondsdata.investitionsgegenstaende"
        :fields="gegenstandFields"
        thead-class="hidden-header"
        class="mb-5 no-border">
      <template #cell(gegenstand)="data">
        <FondsObjekt :gegenstandItems="data.item.gegenstandItems"></FondsObjekt>
      </template>
    </b-table>

    <h4 class="text-uppercase h4-color">Kennzahlen</h4>
    <div v-if="hasNumbers(hasToken)">
      <table class="table table-striped table-bordered">
        <colgroup>
          <col style="width: 40%;"/>
          <col style="width: 60%"/>
        </colgroup>
        <tbody>
        <tr v-if="fondsdata.gik !== null">
          <td class="font-weight-bold">Gesamtinvestition (Ist)*</td>
          <td>{{ formatPriceNoCents(fondsdata.gik) }} {{ fondsdata.currency }}</td>
        </tr>
        <tr v-if="fondsdata.gek !== 0">
          <td class="font-weight-bold">Eigenkapital (Ist)*</td>
          <td>{{ formatPriceNoCents(fondsdata.gek) }} {{ fondsdata.currency }}</td>
        </tr>
        <tr v-if="fondsdata.fk !== null">
          <td class="font-weight-bold">Fremdkapital Emissionsjahr (Ist)*</td>
          <td>{{ formatPriceNoCents(fondsdata.fk) }} {{ fondsdata.currency }}</td>
        </tr>
        <tr v-if="hasToken && fondsdata.fkQuote !== null">
          <td class="font-weight-bold">Fremdkapitalquote (Schließung)*</td>
          <td>{{ formatPercent(fondsdata.fkQuote) }} %</td>
        </tr>
        <tr v-if="fondsdata.fkAktuell !== 0">
          <td class="font-weight-bold">Aktuelles Fremdkapital</td>
          <td>
            {{ formatPriceNoCents(fondsdata.fkAktuell) }} {{ fondsdata.currency }} (31.12.{{
              fondsdata.fkAktuellYear
            }})
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div v-else>
      <p>Keine Kennzahlen vorhanden.</p>
    </div>

    <p class="mb-5">(*) Bis Ende der Platzierungsphase.</p>
    <div v-if="hasToken">
      <h4 class="text-uppercase h4-color">Auszahlung (Ist)</h4>
      <b-table
          bordered striped
          :items="fondsdata.ausschuettungen"
          :fields="ausschuettungenFields"
          thead-class="hidden-header">
        <template #cell(jahr)="data">
          {{ data.item.jahr }}
        </template>
        <template #cell(wert)="data">
          <span v-if="data.item.wert">{{ formatPercent(data.item.wert) }} %</span>
        </template>
      </b-table>
    </div>
  </div>

</template>

<script>
import priceMixin from "@/mixins/formatPrice";
import percentMixin from "@/mixins/formatPercent";
import FondsObjekt from "@/components/fondsdetails/FondsObjekt";

export default {
  name: "Fondsportrait",
  components: {FondsObjekt},
  props: {
    fondsdata: {},
  },
  computed: {
    gegenstandFields() {
      return [
        {key: 'gegenstand', label: '', tdClass: 'col-11',}
      ]
    },
    ausschuettungenFields() {
      return [
        {key: 'jahr', label: '', tdClass: 'col-40-percent',},
        {key: 'wert', label: '', tdClass: 'col-60-percent',}
      ]
    },
    hasToken() {
      return this.$store.getters["auth/hasToken"];
    },
  },
  methods: {
    hasNumbers(requiredToken) {
      if (requiredToken) {
        return this.fondsdata.gik !== null || this.fondsdata.gek !== 0 || this.fondsdata.fk !== null || this.fondsdata.fkAktuell !== 0
            || this.fondsdata.fkQuote !== null;
      }

      return this.fondsdata.gik !== null || this.fondsdata.gek !== 0 || this.fondsdata.fk !== null || this.fondsdata.fkAktuell !== 0;
    }
  },
  mixins: [priceMixin, percentMixin],
}
</script>

<style lang="scss">

.table.no-border > tbody > tr > td {
  border-top: 0 !important;
  padding: 0;

  .table td {
    border: 1px solid #DEE2E6;
  }
}

</style>