<template>
  <div v-if="!fondsdata">
    <p>Keine Daten vorhanden</p>
  </div>
  <div v-else class="text-left pb-3 pt-4">
    <h4 class="text-uppercase h4-color">Beteiligungsdaten</h4>

    <p v-if="!(hasParticipation(hasToken) && hasSecondParticipation)">
      Keine Daten vorhanden
    </p>

    <table v-if="hasParticipation(hasToken)" class="table table-striped table-bordered mb-5">
      <colgroup>
        <col style="width: 40%"/>
        <col style="width: 60%"/>
      </colgroup>
      <tbody>
      <tr v-if="fondsdata.mindestbeteiligung !== 0">
        <td class="font-weight-bold">Mindestbeteiligung</td>
        <td>{{ formatPriceNoCents(fondsdata.mindestbeteiligung) }} {{ fondsdata.currency }}</td>
      </tr>
      <tr v-if="fondsdata.mindeststueckelung !== 0">
        <td class="font-weight-bold">Stückelung</td>
        <td>{{ formatPriceNoCents(fondsdata.mindeststueckelung) }} {{ fondsdata.currency }}</td>
      </tr>
      <tr v-if="fondsdata.agio !== 0">
        <td class="font-weight-bold">Ausgabeaufschlag</td>
        <td>{{ formatPercent(fondsdata.agio) }}%</td>
      </tr>
      <tr v-if="hasToken && fondsdata.bareinlage !== null">
        <td class="font-weight-bold">Eigenfinanzierte Einlage</td>
        <td>{{ formatPercent(fondsdata.bareinlage) }}%</td>
      </tr>
      </tbody>
    </table>
    <table v-if="hasSecondParticipation(hasToken)" class="table table-striped table-bordered">
      <colgroup>
        <col style="width: 40%"/>
        <col style="width: 60%"/>
      </colgroup>
      <tbody>
      <tr v-if="hasToken && fondsdata.mindestbeteiligungzwm !== null">
        <td class="font-weight-bold">Mindestbeteiligung (Zweitmarkt)</td>
        <td>{{ formatPriceNoCents(fondsdata.mindestbeteiligungzwm) }} {{ fondsdata.currency }}</td>
      </tr>
      <tr v-if="hasToken && fondsdata.mindeststueckelungzwm !== null">
        <td class="font-weight-bold">Stückelung (Zweitmarkt)</td>
        <td>{{ formatPriceNoCents(fondsdata.mindeststueckelungzwm) }} {{ fondsdata.currency }}</td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import percentMixin from "@/mixins/formatPercent";
import priceMixin from "@/mixins/formatPrice";

export default {
  name: "Beteiligungsdaten",
  props: {
    fondsdata: {},
  },
  computed: {
    hasToken() {
      return this.$store.getters["auth/hasToken"];
    }
  },
  methods: {
    hasParticipation(requiredToken) {
      if (requiredToken) {
        return this.fondsdata.mindestbeteiligung !== 0 || this.fondsdata.mindeststueckelung !== 0 ||
            this.fondsdata.agio !== 0 || this.fondsdata.bareinlage !== null;
      }

      return this.fondsdata.mindestbeteiligung !== 0 || this.fondsdata.mindeststueckelung !== 0 ||
          this.fondsdata.agio !== 0;
    },
    hasSecondParticipation() {
      return this.fondsdata.mindestbeteiligungzwm !== null || this.fondsdata.mindeststueckelungzwm !== null;
    }
  },
  mixins: [percentMixin, priceMixin],

}
</script>

<style scoped>

</style>