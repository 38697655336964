<template>
  <div>
    <h4 class="text-uppercase text-left h4-color pt-4">Nachrichten</h4>
    <div v-if="unauthorized">
      <div class="alert alert-warning" role="alert">
        <p>Sie haben hierfür keine Berechtigung.</p>
      </div>
    </div>
    <div v-else-if="notfound">
      <div class="alert alert-warning" role="alert">
        <p>Dieser Fonds konnte nicht ermittelt werden.</p>
      </div>
    </div>
    <div v-else-if="servererror">
      <div class="alert alert-danger" role="alert">
        <p>Es ist ein Fehler aufgetreten.</p>
        <p v-if="error" class="fs-7">{{ error }}</p>
      </div>
    </div>
    <div v-else>
      <b-table id="newstable" ref="newstable"
               bordered
               striped
               responsive="xl"
               :isBusy.sync="loading"
               :items="newsProvider"
               :fields="fields"
               :per-page="perPage"
               :current-page="currentPage"
               :key="rerenderTrigger"
               thead-class="hidden-header"
      >
        
        <template #cell(newscontent)="data">
          <div class="small mb-2">{{data.item.timestamp}}</div>
          <div class="font-weight-bold">{{data.item.title}}</div>
          <div>{{data.item.content}}</div>
        </template>

        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong class="ml-2">Die Daten werden geladen...</strong>
          </div>
        </template>
      </b-table>
      
      <b-pagination
          v-model="currentPage"
          :total-rows="newscount"
          :per-page="perPage"
          aria-controls="newstable"
          align="right"
          v-if="newscount > perPage"
      ></b-pagination>
      <div class="text-right fs-7 mb-3">
        Es wurden {{ newscount }} Nachrichten zu diesem Fonds gefunden. 
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Nachrichten",
  data: () => ({
    newsdata: null,
    error: null,
    loading: true,
    notfound: false,
    unauthorized: false,
    servererror: false,
    currentPage: 1,
    perPage: 25,
    newscount: 0,
    rerenderTrigger: 0,
  }),
  computed: {
    fields() {
      return [
        { key: 'newscontent', label: '', tdClass: 'text-left', },
      ]
    },  
  },
  methods: {
    forceRerender() {
      this.rerenderTrigger += 1;
    },
    newsProvider( ctx, callback ) {
      this.loading = true;
      const config = {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
          'XClacksOverhead': 'GNU Terry Pratchett',
          'X-API-KEY': this.$store.getters.getAllEnvis.API_KEY,
        },
        params: {
          subsite: this.$store.getters["getSubsite"],
          trancheId: this.$route.params.id,
          page: ctx.currentPage - 1,
          size: ctx.perPage,
        }
      }
      axios.get( this.$store.getters.getAllEnvis.API_PRODUKTE_ZWM_TRANCHE_NEWS + this.$route.params.id, config )
          .then( response => {
            this.newscount = response.data.newscount;
            this.loading = false;
            callback( response.data.daten );
          }, error => {
            if (error.response.status === 401) {
              this.unauthorized = true;
            }
            else if ( error.response.status === 404 ) {
              this.notfound = true;
            }
            else {
              this.servererror = true;
            }
            this.loading = false;
            this.error = error;
            callback( [] );
          } )
          .catch(error => {
            console.log(error);
            this.error = error;
            this.loading = false;
            callback( [] );
          })
    },
  }
}
</script>

<style scoped>

</style>